/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import { NEW_API } from './config';
import lastStep from './lastStep';

const createSession = source_identifier => {
  const propertyDetails =
    JSON.parse(window.localStorage.getItem('propertyDetails')) || null;
  const lead = JSON.parse(window.localStorage.getItem('lead')) || null;
  const currentSource =
    JSON.parse(window.localStorage.getItem('current_source')) || '';

  if (
    !propertyDetails ||
    (propertyDetails && currentSource !== source_identifier)
  ) {
    axios({
      url: `${NEW_API}/kc-customers-service/sessions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: { source_identifier, lead },
    })
      .then(response => {
        if (response.status === 201) {
          const { access_token, refresh_token, ...data } = response.data;
          lastStep(data.id, access_token);
          window.localStorage.setItem(
            'current_source',
            JSON.stringify(source_identifier)
          );
          window.localStorage.setItem('token', JSON.stringify(access_token));
          window.localStorage.setItem('refresh', JSON.stringify(refresh_token));
          window.localStorage.setItem('propertyDetails', JSON.stringify(data));
        }
      })
      .catch(e => console.log(e));
  }
};

export default createSession;
