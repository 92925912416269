/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import { NEW_API } from './config';

const lastStep = (id, token) => {
  const propertyDetails =
    JSON.parse(window.localStorage.getItem('propertyDetails')) || null;
  if (!propertyDetails) {
    axios({
      url: `${NEW_API}/kc-customers-service/sessions/${id}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: { last_visited_step: 'homepage' },
    })
      .then(response => {
        if (response.status === 201) {
          window.localStorage.setItem(
            'last_visited_step',
            JSON.stringify(response.data.last_visited_step)
          );
        }
      })
      .catch(e => console.log(e));
  }
};

export default lastStep;
