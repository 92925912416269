import React from 'react';
import styled from 'styled-components';
import Layout from 'elements/Layout/Layout';
import PartnersSection from 'pageElements/index/PartnersSection';
import HeroSection from 'pageElements/index/HeroSection';
import CallActionSection from 'elements/CallActionSection/CallActionSection';
import StepsSection from 'pageElements/index/Steps/StepsSection';
import H from 'components/H/H';
import P from 'components/P/P';
import { useAppContext } from '../../store';
import MainButtonsContainer from '../sharedElements/MainButtonsContainer';

const StyledParagraphWrapper = styled.div`
  margin: 13px auto 30px;
  max-width: 447px;

  ${({ theme }) => theme.phone`
    margin: 30px auto;
    padding: 0 25px;
  `};
`;

const Index = () => {
  const { intl } = useAppContext();

  return (
    <Layout>
      <HeroSection />
      <PartnersSection />
      <StepsSection />
      <CallActionSection
        sectionMargin={{ desktop: '-71px 0 126px', mobile: '90px 0 50px;' }}
        containerPadding={{
          desktop: '100px 0 90px',
          mobile: '64px 0;',
        }}
      >
        <>
          <H as="h3" color="white">
            {intl.get('app.home.banner.button.quotes')}
          </H>
          <StyledParagraphWrapper>
            <P isSmall>{intl.get('app.home.banner.button.subtitle.quotes')}</P>
          </StyledParagraphWrapper>
          <MainButtonsContainer gaCategory="Home" centerButtons />
        </>
      </CallActionSection>
    </Layout>
  );
};

export default Index;
