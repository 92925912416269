import React, { useEffect } from 'react';
import { withRouter } from 'next/router';
import HomePageTemplate from 'pageElements/index/HomePageTemplate';
import createSession from '../src/shared/config/sessions';
import { ORGANIC_SOURCE_IDENTIFIER } from '../src/shared/config/config';

export default withRouter(({ router }) => {
  let eventUrl;
  useEffect(
    () => {
      const params = new URLSearchParams(window.location.search);
      const campaign = params.get('campaign');
      const agentId = params.get('agent_id');
      const source_identifier = params.get('source_identifier');
      eventUrl = source_identifier === '9366265005' ? source_identifier : null;
      const leadEvent = params.get('lead');
      localStorage.setItem(
        'eventPage',
        JSON.stringify({ campaign, agentId, source_identifier, leadEvent })
      );
      const lead = JSON.parse(window.localStorage.getItem('lead'));
      const isCommandIframe =
        router.query &&
        router.query.lead &&
        router.query.lead === 'commandiframe';

      if (!lead || (lead && isCommandIframe)) {
        window.localStorage.setItem('tag', JSON.stringify(null));

        window.localStorage.setItem(
          'lead',
          JSON.stringify(isCommandIframe ? 'COMMANDIFRAME' : 'ORGANIC')
        );
      }

      if (isCommandIframe) {
        router.push('/');
      }
    },
    [router]
  );

  useEffect(() => {
    createSession(eventUrl || ORGANIC_SOURCE_IDENTIFIER);
  }, []);

  return <HomePageTemplate />;
});
